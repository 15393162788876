import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Badge,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import classnames from 'classnames';
import WebIcon from 'mdi-react/WebIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import TwitterIcon from 'mdi-react/TwitterIcon';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import * as Sentry from '@sentry/browser';

import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import AccountBoxIcon from 'mdi-react/AccountBoxIcon';
import WrenchIcon from 'mdi-react/WrenchIcon';
import NoteIcon from 'mdi-react/NoteIcon';
import TelephoneIcon from 'mdi-react/TelephoneIcon';
import MapIcon from 'mdi-react/MapIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon'
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import PlusIcon from 'mdi-react/PlusIcon';

import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';
import ContactItem from './ContactItem';
import Form from './Form';
import ActivityList from '../../components/activities/ActivityList';
import NoteList from '../../components/notes/NoteList';
import NoteForm from '../../components/notes/NoteForm';
import CompanyDocuments from '../../components/companyDocuments/';
import SalesInfo from '../../components/sales/';
import SalesHistory from '../../components/sales/SalesHistory';
// import TaskItem from '../tasks/TaskItem';
import SalesHistories from '../../components/salesHistories';
import SalesHistoryProductClass from '../../components/sales/SalesHistoryProductClass';
import Loading from '../../components/Loading';

import ErrorBoundary from '../../components/ErrorBoundary';

// api
import { getCompany } from '../../api/company';
import { deleteContact } from '../../api/company';
import { getTaskList } from '../../api/task';

// helpers
import { renderStars } from '../../components/helpers';

const CompanyForm = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [company, setCompany] = useState({
    name: '',
    email: '',
    website: '',
    contacts: [],
    company_documents: [],
    social_networks: [],
    sales_data: null,
  })
  const [pageTitle, setPageTitle] = useState('New Company')
  const [activeTab, setActiveTab] = useState('info')
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadCompany()
  }, [id])

  // change tabs
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  // api call to load company data.
  const loadCompany = () => {
    setLoading(true)
    if (id !== undefined) {
      getCompany(id)
        .then((data) => {
          setCompany(data)
          setModal(false)
          setPageTitle(data.name)
          setLoading(false)
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  }

  // pagetitle.
  const fullPageTitle = () => {
    const { as400_id } = company
    if (as400_id !== undefined && as400_id !== null && as400_id !== '') {
      return `${pageTitle} (${as400_id})`;
    }
    return pageTitle
  }

  // delete a contact
  const contactDelete = (contactId) => {
    if (window.confirm('Are you sure?')) {
      deleteContact(contactId, id)
        .then(data => {
          console.log(data);
          if (data.status === 'success') {
            loadCompany(); 
          }
        });
    }
  }

  const goBack = event => {
    event.preventDefault();
    navigate("/companies")
  }

  // const resetState = () => {
  //   this.setState({
  //     id: '',
  //     company: {
  //       name: '',
  //       email: '',
  //       website: '',
  //       contacts: [],
  //       company_documents: [],
  //       social_networks: [],
  //       sales_data: null,
  //     },
  //     pageTitle: 'New Company',
  //     activeTab: 'activities',
  //     modal: false,
  //   });
  // }

  // renders

  // render contact list
  const renderContacts = () => {
    const { contacts } = company
    return contacts.map(contact => (
      <ContactItem contact={contact} companyId={id} key={contact.id} deleteContact={(id) => contactDelete(id)} />
    ))
  }

  // telephone
  const renderTelephone = (company) => {
    const { telephone } = company

    if (telephone === null || telephone === '') { return null; }

    return (
      <>
        <a href={`tel:${telephone}`} className="btn btn-primary">
          <TelephoneIcon size={16} />
          {' '}
          {telephone}
        </a>
        <br />
        <br />
      </>
    )
  }

  // links - website, email
  const renderIconLinks = () => {
    const { website, email, source } = company
    const websiteLink = () => {
      if (website !== null && website !== '' && website !== 'null') {
        return (
          <a
            href={website}
            title={website}
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            <WebIcon size={16} />
            &nbsp;
            Website
          </a>
        );
      }
      return '';
    };
    const emailLink = () => {
      if (email !== null && email !== '' && email !== 'null') {
        return (
          <span className="mt-2">
            <a href={`mailto:${email}`} title={email} className="btn btn-primary">
              <EmailIcon size={16} />
              &nbsp;
              {email}
            </a>
          </span>
        );
      }
      return '';
    };

    const displaySource = () => {
      if (source !== null && source !== '' && source !== 'null') {
        return (
          <div>
            <br />
            <small>Source</small>
            <br />
            <Badge color="primary">
              {source}
            </Badge>
          </div>
        )
      }
      return ''
    }

    return (
      <>
        {websiteLink()}
        {emailLink()}
        {displaySource()}
      </>
    );
  }

  const renderNewContactButton = () => {
    const url = `/companies/${id}/contacts/new`;
    return (
      <Link to={url} className="btn btn-primary">
        <PlusIcon size={16} />
        {' '}
        New Contact
      </Link>
    );
  }

  const renderTerms = (company) => {
    const { terms } = company
    if (terms === undefined || terms === null || terms === '') {
      return null;
    }

    return (
      <>
        <br />
        <br />
        <Badge className="mr-1" color="secondary">
          Terms:
          {' '}
          <strong>
            {terms}
          </strong>
        </Badge>
      </>
    )
  }

  const renderNewNote = () => {
    return <NoteForm objectName="company" key="NoteForm" />
  }

  const renderNotes = () => {
    return null;
  }

  const renderLoading = () => {
    if (loading) {
      return <Loading />
    }
  }

  // RENDER!
  return (
    <>
      {renderLoading()}
      {!loading && (
        <ErrorBoundary>
          <PageHeader title={fullPageTitle()} stars={company.stars} />
          <Row>
            <Col className="text-right">
              <button role="button" className="btn btn-primary" onClick={toggleModal}>
                <WrenchIcon size={16} />
                &nbsp; Edit
              </button>
              &nbsp;
              <button
                onClick={event => goBack(event)}
                className="btn btn-primary"
              >
                <ArrowBackIcon size={16} />
                &nbsp; Back
              </button>
            </Col>
          </Row>
          <br />
          
          <div className="row">
            <div className="col-12">
              <CardWrapper renderBody={false}>
                <Nav tabs className="profile-tab">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "info"
                      })}
                      onClick={() => {
                        toggle("info")
                      }}
                    >
                      <AccountBoxIcon size={16} /> Main
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "activities"
                      })}
                      onClick={() => {
                        toggle("activities");
                      }}
                    >
                      <FormatListBulletedIcon size={16} /> Activities
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({active: activeTab === "tasks"})}
                      onClick={() => this.toggle("tasks")}
                    >
                      <CheckBoxIcon size={16} /> Tasks
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "notes" })}
                      onClick={() => {
                        toggle("notes");
                      }}
                    >
                      <NoteIcon size={16} /> Notes
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "contacts" })}
                      onClick={() => {
                        this.toggle("contacts");
                      }}
                    >
                      <AccountBoxIcon size={16} /> Contacts{" "}
                      <span className="label label-primary">
                        {company.contacts.length}
                      </span>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "documents"
                      })}
                      onClick={() => {
                        toggle("documents");
                      }}
                    >
                      <FileDocumentIcon size={16} /> Documents{" "}
                      <span className="label label-primary">
                        {company.company_documents.length}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "salesHistory"
                      })}
                      onClick={() => {
                        toggle("salesHistory");
                      }}
                    >
                      <ChartBarIcon size={16} /> Sales History
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="info">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <Row>
                            <Col xs={12} md={3} className="mb-2">
                              <div className="m-t-30 text-center">
                                <img
                                  alt={company.name}
                                  src={company.logo}
                                  className="img-circle"
                                  width={150}
                                  height={150}
                                />
                                <h4 className="card-title m-t-10">{company.name}</h4>
                                {renderStars(company.stars)}
                              </div>                  
                            
                              <address>
                                {company.address}
                                <br />
                                {company.city} {company.state} {company.zip_code}
                              </address>
                              <a
                                href={`https://www.google.com/maps/search/?api=1&query=${company.address} ${company.city} ${company.state} ${company.zip_code}`}
                                target="_blank"
                                className="btn btn-primary"
                                rel="noreferrer"
                              >
                                <MapIcon size={16} /> View Map
                              </a>
                              <br />
                              <br />
                              {renderTelephone(company)}
                              {renderIconLinks()}
                              {renderTerms(company)}
                            </Col>
                            <Col xs={12} md={3}>
                              <SalesInfo salesData={company.sales_data} />
                            </Col>
                            <Col xs={12} md={6}>
                              {company.sales_data !== null && (
                                <SalesHistory
                                  salesHistories={company.sales_data.sales_histories}
                                />
                              )}

                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xs={12} md={4}>
                              <SalesHistoryProductClass companyId={id} />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <hr />
                      <h3>
                        Contacts
                        {' '}
                        <span className="label label-primary">
                          {company.contacts.length}
                        </span>
                      </h3>
                      <Row className="p-b-20">
                        <Col className="text-right">
                          {renderNewContactButton()}
                        </Col>
                      </Row>
                      <Row>{renderContacts()}</Row>
                    </div>
                  </TabPane>
                  <TabPane tabId="activities">
                    <div className="card-body">
                      <Row>
                        <Col>
                          <ActivityList companyId={id} />
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  {/* <TabPane tabId="contacts">
                    <div className="card-body">
                      <Row className="p-b-20">
                        <Col className="text-right">
                          {this.renderNewContactButton()}
                        </Col>
                      </Row>
                      <Row>{this.renderContacts()}</Row>
                    </div>
                  </TabPane> */}
                  <TabPane tabId="tasks">
                    <div className="card-body">
                      <h5>Coming Soon.</h5>
                    </div>
                  </TabPane>
                  <TabPane tabId="notes">
                    <div className="card-body">
                      <Row>
                        <Col>
                          <NoteList objectId={id} objectName="company" />
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tabId="documents">
                    <div className="card-body">
                      <CompanyDocuments
                        companyDocuments={company.company_documents}
                        companyId={id}
                        refreshCompany={loadCompany}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="salesHistory">
                    <div className="card-body">
                      <SalesHistories companyId={id} />
                    </div>
                  </TabPane>
                </TabContent>
              </CardWrapper>
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>Edit Company</ModalHeader>
            <ModalBody>
              <Form
                company={company}
                refreshCompany={() => loadCompany(id)}
                key={company.updated_at}
              />
            </ModalBody>
          </Modal>
        </ErrorBoundary>
      )}
    </>
  )
}

export default CompanyForm
