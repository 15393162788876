import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

import ErrorBoundary from '../ErrorBoundary';
import CardWrapper from '../CardWrapper';
import Loading from '../Loading';

import { getSalesDataForRep } from '../../api/report';

const SalesOverview = ({ userId, segment, updateStatus }) => {
  const [chartData, setChartData] = useState(null);
  const [loadingChartData, setLoadingChartData] = useState(true);
  const [years, setYears] = useState([2024, 2025]);

  // const y = new Date();
  // setYears([y.getFullYear(), y.getFullYear() - 1]);

  useEffect(() => {
    if (userId !== null) {
      loadChartData();
    }
    console.log(`reloading chart - ${userId}`);
  }, [userId, segment]);

  const loadChartData = () => {
    const startYear = years[0];
    const endYear = years[1];
    getSalesDataForRep({ userId, startYear, endYear, segment })
      .then((data) => {
        setChartData(data.chartData);
        setLoadingChartData(false);
        updateStatus('overview', false);
      });
  };

  const options = {
    responsive: true,
    tooltips: {
      // mode: 'label',
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
          var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return formatter.format(label);
        },
      },
    },
    elements: {
      line: {
        fill: false,
      },
    },
  };

  const Chart = ({ data }) => {
    return (
      <Bar
        title="Sales YTD vs LYTD"
        height={100}
        data={data}
        options={options}
        redraw={false}
      />
    );
  };

  return (
    <CardWrapper>
      {loadingChartData ? (
        <Loading />
      ) : (
        <ErrorBoundary message="Issue loading Chart Data">
          <Row>
            <Col>
              <h4>Sales Overview</h4>
            </Col>
          </Row>
          <Chart data={chartData} />
        </ErrorBoundary>
      )}
    </CardWrapper>
  );
};

SalesOverview.propTypes = {
  userId: PropTypes.string,
  segment: PropTypes.any,
  updateStatus: PropTypes.func,
}


export default SalesOverview;
